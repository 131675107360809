export const getUtm = () => {
  const urlParams = new URLSearchParams(window.location.search)
  // eslint-disable-next-line camelcase
  const utm_term = urlParams?.get('utm_term') || ''
  // eslint-disable-next-line camelcase
  const utm_source = urlParams?.get('utm_source') || ''
  // eslint-disable-next-line camelcase
  const utm_medium = urlParams?.get('utm_medium') || ''
  // eslint-disable-next-line camelcase
  const utm_campaign = urlParams?.get('utm_campaign') || ''
  // eslint-disable-next-line camelcase
  const utm_content = urlParams?.get('utm_content') || ''

  const utmParamsList = {}
  // eslint-disable-next-line camelcase
  if (utm_term) {
    // eslint-disable-next-line camelcase
    utmParamsList.utm_term = utm_term
  }
  // eslint-disable-next-line camelcase
  if (utm_source) {
    // eslint-disable-next-line camelcase
    utmParamsList.utm_source = utm_source
  }
  // eslint-disable-next-line camelcase
  if (utm_medium) {
    // eslint-disable-next-line camelcase
    utmParamsList.utm_medium = utm_medium
  }
  // eslint-disable-next-line camelcase
  if (utm_campaign) {
    // eslint-disable-next-line camelcase
    utmParamsList.utm_campaign = utm_campaign
  }
  // eslint-disable-next-line camelcase
  if (utm_content) {
    // eslint-disable-next-line camelcase
    utmParamsList.utm_content = utm_content
  }

  return utmParamsList
}
